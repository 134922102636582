<template>
  <div
    class="group flex flex-row items-start gap-2 rounded-2xl border-[1.5px] border-solid border-black-10 p-4 transition-all duration-100 hover:border-primary-30 hover:bg-black-03 active:border-primary-80"
  >
    <div class="flex w-1/12">
      <span class="flex size-8 items-center justify-center rounded-full bg-primary-05">
        <UiIcon
          :name="itemsTypeMap.get(vModel.type.code)?.icon || 'info-circle'"
          size="xs"
          class="text-primary-100"
        ></UiIcon>
      </span>
    </div>
    <div class="flex flex-1 flex-col gap-3">
      <component
        :is="itemsTypeMap.get(vModel.type.code)?.component"
        v-if="itemsTypeMap.get(vModel.type.code)"
        v-model="vModel"
        @open-activity="$emit('open-activity', $event)"
      ></component>
    </div>
    <NotificationsItemMarkAsRead v-model="vModel" />
  </div>
</template>

<script setup lang="ts">
import type { Component } from 'nuxt/schema'
import type { InternalNotification, LibraryItem } from '@/types'

const emits = defineEmits(['update:modelValue', 'open-activity'])

type Props = {
  modelValue: InternalNotification
}
const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)

type Item = {
  icon: string
  component: Component
}

const notificationTypes = ref<LibraryItem[]>([])

onNuxtReady(async () => {
  await getNotificationTypes()
})

const getNotificationTypes = async () => {
  notificationTypes.value = await useNotificationTypes()
}

const itemsTypeMap = new Map<string, Item>([
  ['lead', { icon: 'kanban-2', component: resolveComponent('NotificationsItemLead') as Component }],
  ['call', { icon: 'call', component: resolveComponent('NotificationsItemCall') as Component }],
  ['user', { icon: 'user', component: resolveComponent('NotificationsItemUser') as Component }],
  ['activity', { icon: 'calendar', component: resolveComponent('NotificationsItemActivity') as Component }],
  ['management', { icon: 'user-group', component: resolveComponent('NotificationsItemManagement') as Component }],
  ['system', { icon: 'tool', component: resolveComponent('NotificationsItemSystem') as Component }],
])
</script>

<style scoped></style>
