<template>
  <input
    :id="vModel.id.toString()"
    :checked="vModel.isUnread"
    type="checkbox"
    :name="vModel.id.toString()"
    class="relative float-left h-6 w-6 appearance-none rounded-full border-primary-20 before:pointer-events-none before:absolute before:ml-[-3.5px] before:mt-[-3.5px] before:h-6 before:w-6 before:bg-transparent before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-[10px] after:w-[10px] after:rounded-full after:content-[''] checked:border-primary-100 checked:outline-success-100 checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[10px] checked:after:w-[10px] checked:after:rounded-full checked:after:border-primary-100 checked:after:bg-primary-100 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:border-primary-100 hover:bg-primary-05 hover:checked:border-primary-120 hover:checked:bg-primary-05 hover:checked:after:bg-primary-120 focus-visible:border-primary-50 focus-visible:bg-primary-05 focus-visible:shadow-none focus-visible:outline-none focus-visible:before:scale-100 focus-visible:before:border focus-visible:before:border-solid focus-visible:before:border-success-100 focus-visible:before:opacity-[1] focus-visible:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus-visible:border-primary-50 checked:focus-visible:after:bg-primary-50 active:border-primary-120 active:bg-primary-20 checked:active:border-primary-120 active:checked:bg-primary-20 checked:active:after:bg-primary-120 disabled:border-black-30 disabled:bg-black-05 disabled:ring-0 disabled:checked:after:bg-black-30 disabled:hover:border-black-30 group-hover:border group-hover:border-solid group-hover:border-primary-20 group-hover:active:border-primary-100"
    :class="{ 'border border-solid !border-primary-100': justMarkedAsRead }"
    @input="input"
  />
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { InternalNotification } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: InternalNotification
  hovered?: boolean
}
const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)

const justMarkedAsRead = ref(false)

const input = (event: Event) => {
  const value = (event.target as HTMLInputElement).checked
  emits('update:modelValue', { ...props.modelValue, isUnread: value })
  justMarkedAsRead.value = !value
}

onMounted(() => {
  onClickOutside(document.getElementById(vModel.value.id.toString()), () => {
    if (justMarkedAsRead.value) justMarkedAsRead.value = false
  })
})
</script>

<style scoped></style>
