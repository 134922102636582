<template>
  <div class="flex flex-col gap-3" @click="uiStore.cleanSidePanel(), navigateTo(`/leads/${modelValue.entity_id}`)">
    <div class="flex flex-row items-center gap-2">
      <div
        v-if="labelsMap.get(modelValue.label)"
        class="text-subhead-3 flex h-7 w-fit flex-row items-center gap-1 rounded-xl px-2"
        :class="[labelsMap.get(modelValue.label)?.bgColor, labelsMap.get(modelValue.label)?.textColor]"
      >
        {{ labelsMap.get(modelValue.label)?.text }}
      </div>
      <p class="text-caption py-2 text-black-70 first-letter:capitalize">
        {{ formatRelative(new Date(modelValue.created_at), new Date()) }}
      </p>
    </div>
    <!-- eslint-disable-next-line -->
    <div  v-html="modelValue.body" class="group-hover:[&>b]:underline"></div>
    <UiButtonBase
      v-if="actionsMap.get(modelValue.action)"
      id="action"
      @click="actionsMap.get(modelValue.action)?.action()"
    >
      {{ actionsMap.get(modelValue.action)?.text }}
    </UiButtonBase>
  </div>
</template>

<script setup lang="ts">
import { formatRelative } from 'date-fns'
import { useUiStore } from '~/store/ui'
import type { InternalNotification } from '@/types'

const uiStore = useUiStore()

defineEmits(['update:modelValue'])

type Props = {
  modelValue: InternalNotification
}
defineProps<Props>()

type Action = {
  text: string
  action: Function
}
const actionsMap = new Map<string, Action>([
  [
    'go_to_pool',
    {
      text: 'Go to pool',
      action: () => navigateTo('/pipelines/pool'),
    },
  ],
])

type Label = {
  text: string
  bgColor: string
  textColor: string
}

const labelsMap = new Map<string, Label>([
  [
    'unfrozen',
    {
      text: 'Unfrozen lead',
      bgColor: 'bg-primary-10',
      textColor: 'text-primary-80',
    },
  ],
])
</script>

<style scoped></style>
