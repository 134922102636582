<template>
  <div class="flex flex-col gap-3" @click="goToLead()">
    <p class="text-caption py-2 text-black-70 first-letter:capitalize">
      {{ formatRelative(new Date(modelValue.created_at), new Date()) }}
    </p>
    <!-- eslint-disable-next-line -->
    <div v-html="modelValue.body" class="group-hover:[&>b]:underline"></div>
  </div>
</template>

<script setup lang="ts">
import { formatRelative } from 'date-fns'
import type { InternalNotification } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

defineEmits(['update:modelValue'])

type Props = {
  modelValue: InternalNotification
}
const props = defineProps<Props>()

const goToLead = () => {
  if (!props.modelValue.lead_id) return
  uiStore.cleanSidePanel()
  navigateTo(`/leads/${props.modelValue.lead_id}`)
}
</script>

<style scoped></style>
