<template>
  <div class="flex flex-col gap-3">
    <p class="text-caption py-2 text-black-70 first-letter:capitalize">
      {{ formatRelative(new Date(modelValue.created_at), new Date()) }}
    </p>
    <div class="group-hover:[&>b]:underline" @click="goToLead()" v-html="modelValue.body"></div>
    <div class="flex flex-row items-center gap-2">
      <UiButtonBase
        id="view_activity"
        :type="modelValue.meet_button ? 'primary' : 'secondary'"
        :disabled="loading"
        @click="getActivity"
        >View activity
      </UiButtonBase>
      <UiButtonBase
        v-if="modelValue.meet_button"
        id="meet_button"
        type="secondary"
        @click="
          navigateTo(modelValue.meet_button, {
            external: true,
            open: {
              target: '_blank',
            },
          })
        "
      >
        <UiIcon name="google-meet" /> Join Google Meet</UiButtonBase
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatRelative } from 'date-fns'
import type { InternalNotification } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'open-activity'])

type Props = {
  modelValue: InternalNotification
}
const props = defineProps<Props>()

const loading = ref(false)
const activity = ref()

const goToLead = () => {
  if (!props.modelValue.lead_id) return
  uiStore.cleanSidePanel()
  navigateTo(`/leads/${props.modelValue.lead_id}`)
}

const getActivity = async () => {
  loading.value = true
  activity.value = await useGetCalendarActivity(props.modelValue.entity_id)
  emits('open-activity', activity.value)
  loading.value = false
}
</script>

<style scoped></style>
